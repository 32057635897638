import { ChangeEvent, useState } from 'react';
import * as api from '../api';
import { consentTemplateURL } from '../config/MiscAPIConfig';
import ErrorBox from '../generic/ErrorBox';
import SpinnerContainer from '../generic/SpinnerContainer';
import { CheckboxGroup } from '../generic/forms';
import { ConsentTemplate, ErrorType } from '../model/types';
import useBooleanState from '../utils/useBooleanState';
import useComponentDidMount from '../utils/useComponentDidMount';

export interface Props {
    accepted: boolean;
    error?: ErrorType | null;
    onChange: (accepted: boolean) => void;
}

export default function ConsentSection({ accepted, error, onChange }: Props) {
    const [errorState, setErrorState] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [consentTemplate, setConsentTemplate] = useState<ConsentTemplate>();

    useComponentDidMount(() => {
        fetchConsent();
    });

    const onCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked as boolean);
    };

    const fetchConsent = () => {
        api.getAnonymously(consentTemplateURL()).then(
            (data: ConsentTemplate) => {
                setConsentTemplate(data);
                setIsLoading.toFalse();
            },
            (error) => {
                setErrorState(error);
                setIsLoading.toFalse();
            },
        );
    };

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <section className="white form-section form-section-checkbox">
                <div className="form-section-info">
                    <h3>Behandling av personopplysninger</h3>
                    <p>
                        Vinmonopolet vil behandle opplysninger om deg i samsvar med gjeldende regler for personvern. Med personopplysninger mener vi
                        navn, adresse, fødselsdato, e-postadresse og telefonnummer. Vi trenger ditt samtykke til at vi kan lagre og bruke disse
                        opplysningene for å kontakte deg, levere varer og vite hvilke varer du har lov til å kjøpe.
                    </p>
                    <p>
                        Les{' '}
                        <a target="_blank" href="https://www.vinmonopolet.no/personvern" rel="noreferrer">
                            Vinmonopolets personvernerklæring
                        </a>{' '}
                        før du samtykker
                    </p>
                </div>
                {consentTemplate && (
                    <fieldset>
                        <CheckboxGroup
                            label={consentTemplate.description}
                            id="consentGiven"
                            name="consentGiven"
                            onChange={onCheckBoxChange}
                            checked={accepted}
                            error={error}
                        />
                    </fieldset>
                )}
                <ErrorBox errors={errorState} />
            </section>
        </SpinnerContainer>
    );
}
