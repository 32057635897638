import { ChangeEventHandler } from 'react';

export interface Props {
    id: string;
    name?: string;
    label: string;
    isChecked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    role?: 'switch';
}

export default function Checkbox({ name, label, isChecked, onChange, role }: Props) {
    return (
        <label className="checkbox-wrapper">
            <input type="checkbox" name={name} checked={isChecked} onChange={onChange} role={role} />
            <span>{label}</span>
        </label>
    );
}
