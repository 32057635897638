import { useContext, useEffect, useState } from 'react';
import * as api from '../api';
import { getCustomerInformation } from '../api/customerLookup';
import { userURL } from '../config/UserAPIConfig';
import ErrorBox from '../generic/ErrorBox';
import PasswordStrengthIndicator, { minimumPasswordLength } from '../generic/PasswordStrengthIndicator';
import SpinnerContainer from '../generic/SpinnerContainer';
import { InputGroup, ZipGroup } from '../generic/forms';
import SubmitButton from '../generic/forms/SubmitButton';
import { UserContext } from '../login/userContext';
import { formatPhoneNumber } from '../model/Forms';
import { ValidationErrors } from '../model/types';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import { getValidationErrors, isValidationErrors } from '../utils/validation';
import ConsentSection from './ConsentSection';

export interface Props {
    onLoggedIn(): void;
}

export default function RegisterForm({ onLoggedIn }: Props) {
    const { login } = useContext(UserContext);
    const [errors, setErrors] = useState<
        ValidationErrors<
            'email' | 'phone' | 'firstName' | 'lastName' | 'line1' | 'postalCode' | 'birthDate' | 'password' | 'checkPassword' | 'consentGiven'
        >
    >({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useBooleanState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [line1, setLine1] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [town, setTown] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    const [consentGiven, setConsentGiven] = useBooleanState();

    useEffect(() => {
        const element = document.querySelector('.form-control.error') as HTMLElement;

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? 'smooth' : 'auto',
                top: element?.offsetTop ?? 0,
            });
        }, 100);
    }, [errors]);

    const handleSubmit = async () => {
        setLoading.toTrue();
        setError(null);

        try {
            await api.postJson(userURL(), {
                firstName,
                lastName,
                line1,
                postalCode,
                town,
                birthDate,
                email,
                phone,
                password,
                checkPassword,
                consentGiven,
            });
            await login(email, password);
            onLoggedIn();
            gtag('event', 'sign_up');
        } catch (error: any) {
            if (!isValidationErrors(error)) {
                setError(error);
            } else {
                setErrors(getValidationErrors(error));
            }
        } finally {
            setLoading.toFalse();
        }
    };

    const customerInformationLookup = async () => {
        if (!phone) return;
        setLoading.toTrue();
        const phoneNumber = formatPhoneNumber(phone);
        setPhone(phoneNumber);

        try {
            const { firstName, lastName, line1, postalCode } = await getCustomerInformation(phoneNumber);
            setFirstName((fn) => fn || firstName || '');
            setLastName((ln) => ln || lastName || '');
            setLine1((l1) => l1 || line1 || '');
            setPostalCode((pc) => pc || postalCode || '');
        } catch {
            // do nothing
        } finally {
            setLoading.toFalse();
        }
    };

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <section className="white centered">
                <h2>Opprett bruker</h2>
                <p>
                    Registrer deg som bruker for å handle i nettbutikken, lagre produkter i egne lister, velge favorittbutikk og abonnere på
                    informasjon fra Vinmonopolet. Det er obligatorisk å fylle ut alle feltene.
                    <br />
                    Når du klikker på "Registrer bruker" bekrefter du at du har
                    <a target="_blank" href="https://www.vinmonopolet.no/personvern" rel="noreferrer">
                        {' '}
                        lest og akseptert Vinmonopolets personvernerklæring
                    </a>
                    .
                </p>
                <ErrorBox errors={error} />
            </section>
            <form onSubmit={preventDefault(handleSubmit)} noValidate>
                <section className="white form-section">
                    <div className="form-section-info">
                        <h3>Kontaktinformasjon</h3>
                    </div>
                    <fieldset>
                        <InputGroup
                            label="E-postadresse *"
                            id="email"
                            name="email"
                            onChange={eventTargetValue(setEmail)}
                            value={email}
                            error={errors.email}
                            type="email"
                            autoComplete="email"
                        />
                        <InputGroup
                            label="Mobiltelefonnummer *"
                            id="phone"
                            name="phone"
                            onChange={eventTargetValue(setPhone)}
                            value={phone}
                            error={errors.phone}
                            type="tel"
                            autoComplete="tel-local"
                            onBlur={customerInformationLookup}
                        />
                    </fieldset>
                </section>
                <section className="white form-section">
                    <div className="form-section-info">
                        <h3>Min informasjon</h3>
                        <p>
                            Kunder over 18 år kan bestille varer med inntil 21 prosent alkohol.
                            <br />
                            Kunder over 20 år kan bestille alle varer i nettbutikken.
                        </p>
                    </div>
                    <fieldset>
                        <InputGroup
                            label="Fornavn *"
                            id="firstName"
                            name="firstName"
                            autoComplete="given-name"
                            onChange={eventTargetValue(setFirstName)}
                            value={firstName}
                            error={errors.firstName}
                        />
                        <InputGroup
                            label="Etternavn *"
                            id="lastName"
                            name="lastName"
                            autoComplete="family-name"
                            onChange={eventTargetValue(setLastName)}
                            value={lastName}
                            error={errors.lastName}
                        />
                        <InputGroup
                            label="Adresse *"
                            id="line1"
                            name="line1"
                            autoComplete="street-address"
                            onChange={eventTargetValue(setLine1)}
                            value={line1}
                            error={errors.line1}
                        />
                        <ZipGroup
                            error={errors.postalCode}
                            zipCodeName="postalCode"
                            zipCodeLabelText="Postnummer *"
                            townLabelText="Poststed"
                            onChange={eventTargetValue(setPostalCode)}
                            onChangeTown={setTown}
                            zipCodeValue={postalCode}
                            townValue={town}
                            className="form-group zip-code"
                        />
                        <InputGroup
                            label="Fødselsdato (DDMMÅÅÅÅ) *"
                            id="birthDate"
                            name="birthDate"
                            onChange={eventTargetValue(setBirthDate)}
                            value={birthDate}
                            error={errors.birthDate}
                            type="text"
                            inputMode="numeric"
                            className="small"
                        />
                    </fieldset>
                </section>
                <section className="white form-section">
                    <div className="form-section-info">
                        <h3>Passord</h3>
                        <p>Passordet må bestå av minst {minimumPasswordLength} tegn.</p>
                    </div>
                    <fieldset>
                        <InputGroup
                            label="Passord *"
                            id="newPassword"
                            name="password"
                            onChange={eventTargetValue(setPassword)}
                            value={password}
                            error={errors.password}
                            type="password"
                            autoComplete="new-password"
                        />
                        <PasswordStrengthIndicator password={password} />
                        <InputGroup
                            label="Bekreft passord *"
                            id="checkPassword"
                            name="checkPassword"
                            onChange={eventTargetValue(setCheckPassword)}
                            value={checkPassword}
                            error={errors.checkPassword}
                            type="password"
                            autoComplete="new-password"
                        />
                    </fieldset>
                </section>
                <ConsentSection accepted={consentGiven} onChange={setConsentGiven} error={errors.consentGiven} />
                <section className="white">
                    <div className="form-buttons-nomargin">
                        <SubmitButton isLoading={isLoading}>Registrer bruker</SubmitButton>
                    </div>
                </section>
            </form>
        </SpinnerContainer>
    );
}
